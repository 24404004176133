import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/vue-query'
import { isEmpty, uniqBy } from 'lodash-es'
import { useInfiniteScroll } from '@vueuse/core'
import { toRefs } from 'vue'

export const useUnAnswerConversation = (groupId = null) => {
    const { execute } = useApi('/api/chat/conversations/un-answer', 'GET')

    const { data, isFetching } = useQuery({
        queryKey: ['chat-un-answers'],
        queryFn: () =>
            execute({
                queries: {
                    group_id: groupId,
                },
            }),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    const totalUnAnswer = computed(() => data.value?.length || 0)

    return {
        totalUnAnswer,
        unAnswers: data,
        isFetching,
    }
}

export const useClientEmails = (clientId) => {
    const { execute } = useApi('/api/mail/conversations/client/{id}', 'GET')

    const {
        data,
        isFetching,
        fetchNextPage: fetchMore,
        hasNextPage: hasMore,
    } = useInfiniteQuery({
        queryKey: ['client-emails', clientId],
        queryFn: ({ pageParam }) => {
            return execute({
                queries: {
                    cursor: pageParam,
                    limit: 20,
                },
                params: {
                    id: clientId,
                },
            })
        },
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (res) =>
            uniqBy(
                res?.pages?.flatMap((x) => x?.data),
                (x) => x?.id
            ),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const onLoadMore = () => {
        if (hasMore.value) {
            fetchMore()
        }
    }

    return {
        onLoadMore,
        data,
        isFetching,
    }
}

export const useConversations = (groupId = null) => {
    const { execute } = useApi('/api/chat/conversations', 'GET')

    const {
        data,
        isFetching,
        fetchNextPage: fetchMore,
        hasNextPage: hasMore,
    } = useInfiniteQuery({
        queryKey: ['chat-conversations'],
        queryFn: ({ pageParam }) => {
            return execute({
                queries: {
                    group_id: groupId,
                    cursor: pageParam,
                    limit: 20,
                },
            })
        },
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (res) =>
            uniqBy(
                res?.pages?.flatMap((x) => x?.data),
                (x) => x?.id
            ),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const onLoadMore = () => {
        if (hasMore.value) {
            fetchMore()
        }
    }

    return {
        onLoadMore,
        data,
        isFetching,
    }
}

export const useConversation = () => {
    const { centralUser } = useAuth()
    const queryClient = useQueryClient()

    const newConversation = (conversation) => {
        updateUnAnswer(conversation.latest_message, conversation)

        queryClient.setQueryData(['chat-conversations'], (data) => ({
            pages:
                data.pages?.map((page, index) => {
                    if (index === 0) {
                        return {
                            ...page,
                            data: [conversation, ...page.data],
                        }
                    }

                    return page
                }) ?? [],
            pageParams: data.pageParams,
        }))
    }

    const updateUnAnswer = (message, room) => {
        queryClient.setQueryData(['chat-un-answers'], (items = []) => {
            if (message.user_id !== centralUser.value.id) {
                if (items.indexOf(room.id) < 0) {
                    return [...items, room.id]
                }

                return items
            }

            return items.filter((i) => i !== room.id)
        })
    }

    const updateEmailUnAnswer = (email, room) => {
        queryClient.setQueryData(['chat-un-answers'], (items = []) => {
            if (email.to === room.chat_room_email?.mail_connection?.email) {
                if (items.indexOf(room.id) < 0) {
                    return [...items, room.id]
                }

                return items
            }

            return items.filter((i) => i !== room.id)
        })
    }

    const newMessage = (room, message) => {
        updateUnAnswer(message, room)
        queryClient.setQueriesData(
            {
                queryKey: ['chat-conversations-messages', room.id],
            },
            (data) => ({
                pages:
                    data.pages?.map((page, index) => {
                        if (index === 0) {
                            return {
                                ...page,
                                data: [message, ...page.data],
                            }
                        }

                        return page
                    }) ?? [],
                pageParams: data.pageParams,
            })
        )
    }

    const newEmail = (room, message) => {
        updateEmailUnAnswer(message, room)
        queryClient.setQueryData(
            ['email-conversations-messages', room.id],
            (data) => ({
                pages:
                    data.pages?.map((page, index) => {
                        if (index === 0) {
                            return {
                                ...page,
                                emails: [message, ...page.emails],
                            }
                        }

                        return page
                    }) ?? [],
                pageParams: data.pageParams,
            })
        )
    }

    const updateConversation = (conversation, email = null) => {
        if (email) {
            updateEmailUnAnswer(email, conversation)
        } else {
            updateUnAnswer(conversation.latest_message, conversation)
        }

        queryClient.setQueryData(['chat-conversations'], (data) => ({
            pages:
                data.pages?.map((page, index) => {
                    const newData = page.data.filter(
                        (i) => i.id !== conversation.id
                    )

                    if (index === 0) {
                        return {
                            ...page,
                            data: [conversation, ...newData],
                        }
                    }

                    return {
                        ...page,
                        data: newData,
                    }
                }) ?? [],
            pageParams: data.pageParams,
        }))
    }

    return {
        newConversation,
        updateConversation,
        newMessage,
        newEmail,
        updateUnAnswer,
        updateEmailUnAnswer,
    }
}

export const useMessages = (room, reference = null) => {
    const state = reactive({
        initialize: false,
    })
    const chatContent = ref(null)

    const { execute } = useApi('/api/chat/conversations/{id}/messages', 'GET')

    const {
        data,
        isFetching,
        fetchNextPage: fetchMore,
        hasNextPage: hasMore,
        isFetched,
    } = useInfiniteQuery({
        queryKey: ['chat-conversations-messages', room.id, reference],
        enabled: !!room,
        queryFn: ({ pageParam }) => {
            return execute({
                params: {
                    id: room.id,
                },
                queries: {
                    group_id: room.group_id,
                    cursor: pageParam,
                    limit: 20,
                },
            })
        },
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (data) =>
            uniqBy(
                data?.pages?.flatMap((x) => x?.data),
                (x) => x?.id
            ).reverse(),
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        refetchInterval: false,
        onSuccess: async () => {
            if (state.initialize) {
                return
            }

            await scrollContentToBottom()
            state.initialize = true
        },
    })

    onMounted(async () => {
        if (!isFetched.value) {
            return
        }

        await scrollContentToBottom()
    })

    useInfiniteScroll(
        chatContent,
        async () => {
            await onLoadMore()
        },
        { distance: 10, direction: 'top' }
    )

    // Scroll content to bottom
    const scrollContentToBottom = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                chatContent.value.scrollTo(0, chatContent.value.scrollHeight)
                resolve()
            }, 2)
        })
    }

    const isBottomContent = () => {
        const { scrollHeight, scrollTop, clientHeight } = chatContent.value

        return scrollHeight - scrollTop - clientHeight < 100
    }

    const onLoadMore = async () => {
        if (hasMore.value) {
            await fetchMore()
        }
    }

    return {
        chatContent,
        data,
        isFetching,
        onLoadMore,
        isBottomContent,
        scrollContentToBottom,
        ...toRefs(state),
    }
}

export const useMessage = (room) => {
    const { centralUser } = useAuth()
    const { handleSubmit } = useForm()
    const {
        value: message,
        errorMessage,
        setValue: setMessage,
        setErrors: setMessageError,
    } = useField('message')

    const { echo } = usePrivateChannel(`chat.room.${room.id}`)
    const { newMessage } = useConversation()

    const { execute: send, loading: loadingSend } = useApi(
        '/api/chat/conversations/{id}',
        'POST'
    )

    watch(
        () => message.value,
        (value) => {
            if (!value) {
                return
            }

            echo.value.whisper('typing', centralUser.value)
        }
    )

    const disabledSend = computed(() => isEmpty(message.value))

    const onSendMessage = () => {
        return new Promise((resolve) => {
            handleSubmit((values) =>
                send({
                    params: {
                        id: room.id,
                        group_id: room.group_id,
                    },
                    data: {
                        ...values,
                        group_id: room.group_id,
                    },
                })
                    .then((msg) => {
                        newMessage(room, msg)
                        setMessage(null)

                        resolve(msg)
                    })
                    .catch(({ errorMessage }) => {
                        setMessageError(errorMessage)
                    })
            )()
        })
    }

    return {
        message,
        errorMessage,
        disabledSend,
        loadingSend,
        send,
        onSendMessage,
        newMessage,
    }
}

export const useEmailMessage = (room) => {
    const { centralUser } = useAuth()
    const { handleSubmit } = useForm()
    const {
        value: message,
        errorMessage,
        setValue: setMessage,
        setErrors: setMessageError,
    } = useField('message')

    const { echo } = usePrivateChannel(`chat.room.email.${room.id}`)
    const { newEmail } = useConversation()

    const { execute: send, loading: loadingSend } = useApi(
        '/api/mail/conversation/{id}',
        'POST'
    )

    watch(
        () => message.value,
        (value) => {
            if (!value) {
                return
            }

            echo.value.whisper('typing', centralUser.value)
        }
    )

    const disabledSend = computed(() => isEmpty(message.value))

    const onSendEmail = () => {
        return new Promise((resolve) => {
            handleSubmit((values) =>
                send({
                    params: {
                        id: room.id,
                    },
                    data: values,
                })
                    .then(({ message, chat_room_email }) => {
                        newEmail(room, message)
                        setMessage(null)

                        resolve({
                            room,
                            message,
                            chat_room_email,
                        })
                    })
                    .catch(({ errorMessage }) => {
                        setMessageError(errorMessage)
                    })
            )()
        })
    }

    return {
        message,
        errorMessage,
        disabledSend,
        loadingSend,
        send,
        onSendEmail,
        newEmail,
    }
}

export const useEmails = (room) => {
    const state = reactive({
        initialize: false,
    })
    const chatContent = ref(null)

    const { execute } = useApi('/api/mail/conversation/{id}/messages', 'GET')
    const {
        data,
        isFetching,
        fetchNextPage: fetchMore,
        hasNextPage: hasMore,
        isFetched,
    } = useInfiniteQuery({
        queryKey: ['email-conversations-messages', room.id],
        enabled: !!room,
        queryFn: ({ pageParam }) =>
            execute({
                queryParams: {
                    cursor: pageParam,
                },
                params: { id: room.id },
            }),
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (data) =>
            uniqBy(
                data?.pages?.flatMap((x) => x?.emails),
                (x) => x?.messageId
            ).reverse(),
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        refetchInterval: false,
        onSuccess: async () => {
            if (state.initialize) {
                return
            }

            await scrollContentToBottom()
            state.initialize = true
        },
    })

    const emails = computed(() => (data.value || []).reverse())

    onMounted(async () => {
        if (!isFetched.value) {
            return
        }

        await scrollContentToBottom()
    })

    useInfiniteScroll(
        chatContent,
        async () => {
            await onLoadMore()
        },
        { distance: 10, direction: 'top' }
    )

    // Scroll content to bottom
    const scrollContentToBottom = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                chatContent.value.scrollTo(0, chatContent.value.scrollHeight)
                resolve()
            }, 2)
        })
    }

    const isBottomContent = () => {
        const { scrollHeight, scrollTop, clientHeight } = chatContent.value

        return scrollHeight - scrollTop - clientHeight < 100
    }

    const onLoadMore = async () => {
        if (hasMore.value) {
            await fetchMore()
        }
    }

    return {
        chatContent,
        data,
        emails,
        isFetching,
        onLoadMore,
        isBottomContent,
        scrollContentToBottom,
        ...toRefs(state),
    }
}
